import { Button as StandaloneButton } from '@/components/Button';
import { CancelSubscriptionSubscriptionCancelledTheme } from '@/components/CancelSubscription/SubscriptionCancelled/theme';
import { RadioButton as StandaloneRadioButton } from '@/components/RadioButton';
import { TextArea as StandaloneTextArea } from '@/components/TextArea';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CancelSubscriptionSubscriptionCancelledTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Description = $({ as: 'p', slot: 'description' });
const Form = $({ as: 'form', slot: 'form' });
const FormHeadline = $({ as: 'h4', slot: 'formHeadline' });
const RadioGroup = $({ as: 'div', slot: 'radioGroup' });
const RadioButton = GenericSlot({
  as: StandaloneRadioButton,
  theme: CancelSubscriptionSubscriptionCancelledTheme.RadioButton,
});
const TextArea = GenericSlot({ as: StandaloneTextArea, theme: CancelSubscriptionSubscriptionCancelledTheme.TextArea });
const Button = GenericSlot({ as: StandaloneButton, theme: CancelSubscriptionSubscriptionCancelledTheme.Button });

export const SubscriptionCancelled = Object.assign(Base, {
  Headline,
  Description,
  Form,
  FormHeadline,
  RadioGroup,
  RadioButton,
  TextArea,
  Button,
});
