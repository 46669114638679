import { tw } from '@/utils/tw';

export function getViewportVisibilityClass({
  viewportVisibility = '',
  hideViewport = { mobile: false, desktop: false },
}: {
  viewportVisibility: string; // Lab3 property,
  hideViewport: { mobile: boolean; desktop: boolean }; //Lab4 property,
}): string {
  const hideMobileClass = `max-sm:hidden`;
  const hideDesktopClass = 'sm:hidden';

  const hideMobile = hideViewport.mobile || viewportVisibility === 'desktop';
  const hideDesktop = hideViewport.desktop || viewportVisibility === 'mobile';

  return tw.join(hideDesktop && hideDesktopClass, hideMobile && hideMobileClass);
}
