import { Slider } from '@/components/Slider';
import { SlideShow } from './SlideShow';

export const StandaloneSlideShow: typeof Slider = (props) => (
  <SlideShow
    options={{
      autoplay: 'true',
      speed: '3000',
      loop: 'true',
      'slides-per-view': '1.1',
    }}
    {...props}
  />
);
