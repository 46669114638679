import { IconTheme } from '@/components/Icon/theme';
import { ImageTheme } from '@/components/Image/theme';
import { LinkTheme } from '@/components/Link/theme';
import { tw } from '@/utils/tw';

export const ListItemLink = tw.theme({
  extend: LinkTheme,
  base: ['flex', 'flex-row', 'items-center', 'gap-x-3'],
});

const ListItemImage = tw.theme({
  extend: ImageTheme,
  slots: {
    base: ['w-16', 'h-16', 'data-[fill]:aspect-square', 'rounded-lg', 'shrink-0', 'bg-primary-100'],
    group: ['w-16'],
  },
});

const ListItemIcon = tw.theme({
  extend: IconTheme,
  defaultVariants: {
    size: 'large',
  },
});

const AuthorsListTheme = tw.theme({
  slots: {
    base: ['flex', 'flex-col', 'gap-y-3'],
    headline: ['text-headline-lg'],
    description: [],
    list: ['mt-3', 'gap-6', 'flex', 'flex-row', 'flex-wrap'],
    listItem: ['w-78', 'p-3', 'transition-colors', 'group', 'cursor-pointer'],
    listItemTitle: ['text-headline-sm', 'grow'],
  },
  variants: {
    colors: {
      default: {
        listItem: ['bg-primary-200', 'hover:bg-primary-100', 'active:bg-primary-200'],
      },
    },
  },
  defaultVariants: {
    colors: 'default',
  },
});

export default Object.assign(AuthorsListTheme, { ListItemLink, ListItemImage, ListItemIcon });
