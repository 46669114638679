import { ExpertSliderTheme } from '@/components/ExpertSlider/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { Slider as StandaloneSlider } from '@/components/Slider';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ExpertSliderTheme });

const Base = $({ as: 'section' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Slider = GenericSlot({ as: StandaloneSlider, theme: ExpertSliderTheme.Slider });
const Image = GenericSlot({ as: StandaloneImage, theme: ExpertSliderTheme.Image });
const Caption = $({ as: 'span', slot: 'caption' });
const Description = $({ as: 'p', slot: 'description' });

export const ExpertSlider = Object.assign(Base, {
  Headline,
  Slider,
  Image,
  Caption,
  Description,
});
