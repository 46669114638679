import { tw } from '@/utils/tw';

const Front = tw.theme({
  base: [
    'group/front',
    'flex',
    'flex-col',
    'min-h-screen',
    'gap-y-grid',
    'm-grid',

    'empty:hidden',

    '[&:not(:has(.fullscreen-parent))]:grid-container',
  ],
});

export default Front;
