import { tw } from '@/utils/tw';
import BaseKeesingGamesTheme from 'base/components/KeesingGames/theme';

const Icon = tw.theme({
  extend: BaseKeesingGamesTheme.Icon,
});

const KeesingGames = tw.theme({
  extend: BaseKeesingGamesTheme,
  slots: {
    linkText: ['text-button-md'],
  },
});

export default Object.assign(KeesingGames, { Icon });
