/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneSubscriptionDetails as ResolvedStandaloneSubscriptionDetails } from 'base/components/standalone/SubscriptionDetails';

export const SubscriptionDetails: typeof ResolvedStandaloneSubscriptionDetails = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneSubscriptionDetails {...props} />
        </ErrorBoundary>
    );
});

export type SubscriptionDetailsProps = PropsFromComponent<typeof SubscriptionDetails>;

/** @deprecated Use slot architecture instead */
export const SubscriptionDetailsWith = (extras: DynamicStandaloneExtras): typeof SubscriptionDetails => {
    return function SubscriptionDetails(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SubscriptionDetails');
        return <ResolvedStandaloneSubscriptionDetails {...mergeProps({ options: { theme } }, props)} />;
    }
}