import { Image } from '@/components/Image';
import { SlideShow } from '@/components/SlideShow';
import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';
import { mapLabradorImageToStandaloneImage } from '@/utils/image';

export const LabradorSlideShow: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const [{ device }] = useAppState();

  const { globalCaption, hideBylines, hideCaptions, aspectRatio } = data ?? {};

  const slides = descendants.map((child, index) => {
    child.data.showCaption = !hideCaptions;
    if (globalCaption) child.data.caption = globalCaption;
    if (hideBylines) child.data.byline = undefined;

    return (
      <Image
        key={index}
        options={{
          style: {
            aspectRatio: aspectRatio?.[device] ? `1/${aspectRatio[device]}` : 'auto',
          },
        }}
        {...mapLabradorImageToStandaloneImage(child)}
      />
    );
  });

  return <SlideShow slides={slides} {...props} />;
};
