import { ImageTheme } from '@/components/Image/theme';
import { SliderTheme } from '@/components/Slider/theme';
import { tw } from '@/utils/tw';

const Slider = tw.theme({
  extend: SliderTheme,
  slots: {
    slide: ['bg-transparent', 'p-2'],
  },
  variants: {
    aspectRatio: {
      vertical: {
        slide: ['!aspect-auto', 'w-40', 'sm:w-70', 'md:w-70'],
      },
    },
  },
  defaultVariants: {
    aspectRatio: 'vertical',
  },
});

const Image = tw.theme({
  extend: ImageTheme,
  slots: {
    base: ['mb-1.5', 'w-full', 'rounded-xl', 'sm:rounded-3xl'],
  },
});

const ExpertSlider = tw.theme({
  slots: {
    base: ['gap-y-3.5', 'mx-auto', 'max-w-full', 'flex', 'flex-col'],
    headline: ['text-headline-2xs', 'uppercase'],
    caption: ['text-headline-2xs'],
    description: ['text-headline-xs'],
  },
});

export default Object.assign(ExpertSlider, { Image, Slider });
