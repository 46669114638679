import { z } from 'zod';

const ContactEditorial = z.object({
  name: z.string().or(z.literal('')).optional(),
  email: z
    .string()
    .email({ message: 'E-postadressen är ogiltig. Den måste innehålla ett @-tecken och en punkt.' })
    .or(z.literal(''))
    .optional(),
  phoneNumber: z
    .string()
    // check if the phone number is a swedish phone number and have 9 - 10 digits
    .regex(/^(\+46)?\d{9,10}$/, {
      message: 'Telefonnumret är ogiltigt. Det måste börja med +46 (valfritt) och följas av 9 eller 10 siffror.',
    })
    .or(z.literal(''))
    .optional(),
  message: z.string().min(1, { message: 'Var god och fyll i fältet.' }),
  legal: z.boolean().optional(),
});

const refinement = ({ name, email, phoneNumber, legal }: z.infer<typeof ContactEditorial>, ctx: z.RefinementCtx) => {
  const isAnyFieldFilled = Boolean(name) || Boolean(email) || Boolean(phoneNumber);

  const isLegalSelected = legal === true;

  if (isAnyFieldFilled && !isLegalSelected) {
    ctx.addIssue({
      path: ['legal'],
      code: z.ZodIssueCode.custom,
      message:
        'Du har angett personuppgifter. För att skicka in ditt meddelande, godkänn vår hantering eller ta bort uppgifterna och skicka meddelandet utan dem.',
    });
  }
};

export const DefaultContactEditorialFormValidationSchema = ContactEditorial.superRefine(refinement);

export const ContactEditorialFormValidationSchemaWithCaptcha = ContactEditorial.extend({
  recaptchaToken: z.string().min(1),
}).superRefine(refinement);
