import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { truncate } from 'lodash';
import { ComponentProps, ReactNode } from 'react';
import { DirektTeaser } from './DirectTeaser';

export interface DirektPost {
  time: string;
  text: string;
  href: string;
}

export interface StandaloneDirectTeaserProps extends StandaloneComponentProps<typeof DirektTeaser> {
  headline?: ReactNode;
  posts?: DirektPost[];
  button?: ComponentProps<typeof DirektTeaser.Button>;
}

export const StandaloneDirektTeaser: StandaloneComponent<StandaloneDirectTeaserProps> = ({
  headline,
  posts,
  button,
  ...props
}) => {
  if (!posts?.length) {
    return null;
  }
  return (
    <DirektTeaser {...props}>
      <DirektTeaser.Headline>
        <DirektTeaser.Blip options={props.options?.$blip} />
        <DirektTeaser.Header>{headline}</DirektTeaser.Header>
      </DirektTeaser.Headline>
      <DirektTeaser.List>
        {posts.map((post) => (
          <DirektTeaser.Item key={post.href}>
            <DirektTeaser.ItemLink
              href={post.href}
              options={props.options?.$itemLink}
              content={
                <>
                  <DirektTeaser.Time dateTime={post.time}>{post.time}</DirektTeaser.Time>
                  <DirektTeaser.ItemText>{truncate(post.text, { length: 135 })}</DirektTeaser.ItemText>
                </>
              }
            />
          </DirektTeaser.Item>
        ))}
      </DirektTeaser.List>
      <DirektTeaser.Button
        href={button?.href}
        content={button?.content}
        options={mergeOptions(props.options?.$button, button?.options)}
      />
    </DirektTeaser>
  );
};
