import { ButtonTheme } from '@/components/standalone/Button/theme';
import { TextFieldTheme } from '@/components/standalone/TextField/theme';
import { tw } from '@/utils/tw';

const ArticlePaywallFormTheme = tw.theme({
  slots: {
    base: [],
    form: [],
    policy: [],
    caption: [],
    fieldset: [],
    provider: [],
    featuresList: [],
    feature: [],
  },
});

export default Object.assign(ArticlePaywallFormTheme, {
  Button: ButtonTheme,
  TextField: TextFieldTheme,
});
