import { tw } from '@/utils/tw';
import BaseSaveArticleTheme from 'base/components/SaveArticle/theme';

const Button = tw.theme({
  extend: BaseSaveArticleTheme.Button,
  variants: {
    size: {
      large: ['w-full', 'md:w-auto'],
    },
  },
  defaultVariants: {
    colors: 'secondary',
    size: 'large',
  },
});

const SaveArticle = tw.theme({
  extend: BaseSaveArticleTheme,
  slots: {
    base: ['text-center', 'order-8', 'col-span-full', 'mt-3'],
    modal: ['[&::backdrop]:bg-black/50', 'p-0'],
  },
});

export default Object.assign(SaveArticle, { Button });
