import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { MorePagesTitle } from './MorePagesTitle';

export interface StandaloneMorePagesTitleProps extends StandaloneComponentProps<typeof MorePagesTitle> {
  content?: ReactNode;
}

export const StandaloneMorePagesTitle: StandaloneComponent<StandaloneMorePagesTitleProps> = ({ content, ...props }) => {
  const [{ type }] = useAppState();

  if (!content) return <></>;

  return (
    <MorePagesTitle data-page-type={type} {...props}>
      {content}
    </MorePagesTitle>
  );
};
