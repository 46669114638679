import { Button as StandaloneButton } from '@/components/Button';
import { CancelSubscriptionSubscriptionCancelled as StandaloneCancelSubscriptionSubscriptionCancelled } from '@/components/CancelSubscription/SubscriptionCancelled';
import { CancelSubscriptionTheme } from '@/components/CancelSubscription/theme';
import { Link as StandaloneLink } from '@/components/Link';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';
const $ = GenericSlotFactory({ theme: CancelSubscriptionTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Description = $({ as: 'p', slot: 'description' });
const ActionsGroup = $({ as: 'div', slot: 'actionsGroup' });
const Button = GenericSlot({ as: StandaloneButton, theme: CancelSubscriptionTheme.Button });
const Link = GenericSlot({ as: StandaloneLink, theme: CancelSubscriptionTheme.Link });
const Form = GenericSlot({
  as: StandaloneCancelSubscriptionSubscriptionCancelled,
  theme: CancelSubscriptionTheme.Form,
});

export const CancelSubscription = Object.assign(Base, { Headline, Description, ActionsGroup, Button, Link, Form });
