import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';

const Image = tw.theme({
  extend: ImageTheme,
});

const AffiliateItem = tw.theme({
  slots: {
    base: `not-prose col-span-12 grid gap-2.5 md:grid-cols-2`,
    counter: `text-body-sm`,
    headline: `text-center text-headline-md md:text-headline-lg`,
    subtitle: `text-body-xs`,
    description: `mb-2 text-center text-body-sm`,
    group: `flex flex-col items-center gap-2.5`,
    product: `flex flex-col gap-2.5 md:row-start-3 md:gap-3`,
    productVendor: `text-center text-body-xs`,
    productHeadline: `text-center text-preamble-md`,
    productCaption: `block text-center underline text-headline-xs md:text-headline-sm`,
  },
  variants: {
    multiple: {
      false: {
        base: `md:grid-rows-[1fr_auto_auto_1fr]`,
        counter: ``,
        headline: ``,
        subtitle: ``,
        description: `md:px-10`,
        group: `justify-center md:col-start-2 md:row-start-2 md:justify-end md:gap-3`,
        product: `md:col-start-2`,
        productVendor: ``,
        productHeadline: ``,
        productCaption: ``,
      },

      true: {
        base: `md:gap-x-6 md:gap-y-4`,
        counter: ``,
        headline: ``,
        subtitle: ``,
        description: `max-w-bodytext`,
        group: `md:col-span-2 md:gap-4`,
        product: `mb-3`,
        productVendor: ``,
        productHeadline: ``,
        productCaption: ``,
      },
    },
  },
  defaultVariants: {
    multiple: false,
  },
});

export default Object.assign(AffiliateItem, { Image });
