import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = ButtonTheme;

const SaveArticle = tw.theme({
  slots: {
    base: [],
    modal: [],
  },
});

export default Object.assign(SaveArticle, { Button });
