import { Button as StandaloneButton } from '@/components/Button';
import { SaveArticleTheme } from '@/components/SaveArticle/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: SaveArticleTheme });

const Base = $({ as: 'div', slot: 'base' });
const Modal = $({ as: 'dialog', slot: 'modal' });
const Button = GenericSlot({ as: StandaloneButton, theme: SaveArticleTheme.Button });

export const SaveArticle = Object.assign(Base, {
  Modal,
  Button,
});
