/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneArticlePaywallForm as ResolvedStandaloneArticlePaywallForm } from 'base/components/standalone/ArticlePaywall/Form';

export const ArticlePaywallForm: typeof ResolvedStandaloneArticlePaywallForm = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneArticlePaywallForm {...props} />
        </ErrorBoundary>
    );
});

export type ArticlePaywallFormProps = PropsFromComponent<typeof ArticlePaywallForm>;

/** @deprecated Use slot architecture instead */
export const ArticlePaywallFormWith = (extras: DynamicStandaloneExtras): typeof ArticlePaywallForm => {
    return function ArticlePaywallForm(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticlePaywallForm');
        return <ResolvedStandaloneArticlePaywallForm {...mergeProps({ options: { theme } }, props)} />;
    }
}