import bylineFallback from '@/allas/public/image/byline-fallback.png';
import { StandaloneArticleHeader } from 'base/components/ArticleHeader';

export const AllasStandaloneArticleHeader: typeof StandaloneArticleHeader = (props) => {
  props.bylines?.forEach((byline) => {
    byline.image = byline.image || bylineFallback;
  });

  return <StandaloneArticleHeader {...props} />;
};
