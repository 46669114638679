import { BlipTheme } from '@/components/Blip/theme';
import { ButtonTheme } from '@/components/Button/theme';
import { LinkTheme } from '@/components/Link/theme';
import { tw } from '@/utils/tw';

const DirektTeaserTheme = tw.theme({
  slots: {
    base: ['p-3', 'border-b-2', 'border-gray-300', 'sm:mx-auto', 'sm:w-full'],
    headline: ['flex', 'items-center', 'gap-1.5', 'mb-1.5'],
    header: ['text-headline-sm'],
    list: [],
    item: ['border-b', 'mb-3', 'pb-3'],
    time: ['text-headline-xs', 'text-gray-500', 'block', 'mb-1'],
    itemText: ['text-headline-md'],
  },
});

export default Object.assign(DirektTeaserTheme, { Blip: BlipTheme, Button: ButtonTheme, Link: LinkTheme });
