import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { zodResolver } from '@hookform/resolvers/zod';
import { ComponentProps, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SubscriptionCancelled } from './SubscriptionCancelled';

export interface CancelSubscriptionFormProps extends StandaloneComponentProps<typeof SubscriptionCancelled> {
  headline?: ReactNode;
  description?: ReactNode;
  formHeadline?: ReactNode;
  radioButtons?: ComponentProps<typeof SubscriptionCancelled.RadioButton>[];
  textArea?: ComponentProps<typeof SubscriptionCancelled.TextArea>;
  button?: ComponentProps<typeof SubscriptionCancelled.Button>;
  validationSchema?: z.ZodObject<any, any>;
}

const defaultValidationSchema = z.object({
  levelOfSatisfaction: z.string().nonempty(),
  message: z.string(),
});

export const StandaloneCancelSubscriptionSubscriptionCancelled: StandaloneComponent<CancelSubscriptionFormProps> = ({
  headline,
  description,
  formHeadline,
  radioButtons,
  textArea,
  button,
  validationSchema,
  ...props
}) => {
  const { handleSubmit, register } = useForm({
    resolver: zodResolver(validationSchema || defaultValidationSchema),
  });

  const displayForm = formHeadline || Boolean(radioButtons?.length) || textArea || button;

  return (
    <SubscriptionCancelled {...props}>
      {headline && <SubscriptionCancelled.Headline>{headline}</SubscriptionCancelled.Headline>}
      {description && <SubscriptionCancelled.Description>{description}</SubscriptionCancelled.Description>}
      {displayForm && (
        <SubscriptionCancelled.Form
          onSubmit={handleSubmit(() => {
            // TODO: add submit to proper service
          })}
        >
          {formHeadline && <SubscriptionCancelled.FormHeadline>{formHeadline}</SubscriptionCancelled.FormHeadline>}
          {Boolean(radioButtons?.length) && (
            <SubscriptionCancelled.RadioGroup>
              {radioButtons!.map((radio, index) => (
                <SubscriptionCancelled.RadioButton
                  key={index}
                  {...mergeProps(radio, {
                    options: mergeOptions({ $input: register('levelOfSatisfaction') }, props.options?.$radioButton),
                  })}
                />
              ))}
            </SubscriptionCancelled.RadioGroup>
          )}
          {textArea && (
            <SubscriptionCancelled.TextArea
              {...mergeProps(textArea, {
                options: mergeOptions({ $input: register('message') }, props.options?.$textArea),
              })}
            />
          )}
          {button && (
            <SubscriptionCancelled.Button
              {...mergeProps(button, { options: { ...mergeOptions({ type: 'submit' }, props.options?.$button) } })}
            />
          )}
        </SubscriptionCancelled.Form>
      )}
    </SubscriptionCancelled>
  );
};
