import { LinkTheme } from '@/components/Link/theme';
import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const ContactUsLink = tw.theme({
  extend: LinkTheme,
  slots: {
    base: `ml-4 flex items-center gap-1.5 text-headline-xs only:ml-0`,
  },
});

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    colors: {
      text: 'text-primary-700',
    },
    variant: {
      text: 'capitalize',
    },
  },
});

const ArticleHeader = tw.theme({
  slots: {
    base: `mx-auto grid w-full max-w-grid gap-3`,
    bylines: `flex-wrap gap-2`,
    date: `text-gray-700 text-body-xs`,
    category: ``,
    description: `text-preamble-md`,
    headline: `text-headline-md sm:text-headline-lg`,
    media: ``,
    tags: `gap-3`,
  },
  variants: {
    variant: {
      first: {
        base: `grid-cols-[auto_1fr]`,
        bylines: `order-5 col-span-2 flex flex-row items-center`,
        date: `order-4 flex self-center`,
        category: `order-3`,
        description: `order-7 col-span-2 mx-auto max-w-article`,
        headline: `order-2 col-span-2`,
        media: `order-6 col-span-2`,
        tags: `order-1 col-span-2 flex`,
      },
      second: {
        base: `grid-cols-[auto_auto_1fr] sm:grid-cols-[1fr_auto_3fr]`,
        bylines: `order-4 col-span-3 flex flex-row items-center sm:col-span-1 sm:row-span-2 sm:self-start`,
        date: `order-6 col-span-2 flex self-center sm:col-span-1`,
        category: `order-5`,
        description: `order-7 col-span-3 sm:col-span-2`,
        headline: `order-2 col-span-3`,
        media: `order-3 col-span-3`,
        tags: `order-1 col-span-3 flex`,
      },
      third: {
        base: `grid-cols-[auto_1fr]`,
        bylines: `order-6`,
        date: `order-7 flex flex-col`,
        category: `order-4 col-span-2`,
        description: `order-5 col-span-2`,
        headline: `order-2 col-span-2`,
        media: `order-3 col-span-2`,
        tags: `order-1 col-span-2 flex`,
      },
      fourth: {
        base: `grid-cols-[auto_1fr] sm:grid-cols-[1fr_auto_auto_1fr]`,
        bylines: `order-6 col-span-2 flex flex-row items-center sm:col-start-2`,
        date: `order-3 flex flex-row self-center sm:col-span-2`,
        category: `order-2 sm:col-start-2`,
        description: `order-5 col-span-2 sm:col-start-2 sm:max-w-article`,
        headline: `order-4 col-span-2 sm:col-start-2 sm:max-w-article`,
        media: `order-1 col-span-2 sm:col-span-4`,
        tags: `order-7 col-span-2 flex sm:col-start-2 sm:max-w-article`,
      },
    },
  },
  defaultVariants: {
    variant: 'first',
  },
});

export default Object.assign(ArticleHeader, { ContactUsLink, Tag });
