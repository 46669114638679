import { Link } from '@/components/Link';
import { useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isReactNode } from '@/types/is';
import { AccessLevel } from '@/utils/parseAccessLevel';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import Tracking from 'base/components/TrackingComponent';
import { ContentLock } from './ContentLock';

export interface StandaloneContentLockProps extends StandaloneComponentProps<typeof ContentLock> {
  accessLevel?: AccessLevel;
  content?: {
    login?:
      | React.ReactNode
      | {
          button?: React.ReactNode;
          caption?: React.ReactNode;
          footer?: React.ReactNode;
          headline?: React.ReactNode;
        };
    purchase?: React.ReactNode;
  };
}

export const StandaloneContentLock: StandaloneComponent<StandaloneContentLockProps> = ({
  accessLevel,
  content,
  ...props
}) => {
  const { logInUrl, signUpUrl } = useUserAuth();

  const stableLoginProps = useStableProps(
    {
      ...withTrackingAttributes({
        category: 'signup_box',
        name: 'article_locked',
      }),
    },
    props.options?.$login,
  );

  const stableLoginFooterProps = useStableProps(
    {
      className: 'cts-tracking-object',
      ...withTrackingAttributes({
        category: 'signup_box',
        name: 'article_locked_signup_button',
        url: signUpUrl,
      }),
    },
    props.options?.$login?.$footer,
  );

  if (accessLevel === 'open') {
    return null;
  }

  return (
    <ContentLock {...props}>
      {accessLevel === 'login' && (
        <Tracking options={{ wrapChildren: true }}>
          <ContentLock.Login {...stableLoginProps}>
            {isReactNode(content?.login) ? (
              content?.login
            ) : (
              <>
                <ContentLock.Login.Headline>
                  {content?.login?.headline ?? 'Vill du läsa resten av den här artikeln?'}
                </ContentLock.Login.Headline>
                <ContentLock.Login.Caption>
                  {content?.login?.caption ??
                    'För att få ut det mesta av din läsupplevelse, skapa ett konto och logga in — det är helt gratis!'}
                </ContentLock.Login.Caption>
                <ContentLock.Login.Button
                  href={logInUrl}
                  options={{
                    rel: 'nofollow',
                    size: 'large',
                    className: 'cts-tracking-object',
                    ...withTrackingAttributes({
                      category: 'signup_box',
                      name: 'article_locked_login_button',
                      url: logInUrl,
                    }),
                  }}
                  content={isReactNode(content?.login) || !content?.login?.button ? 'Logga in' : content?.login?.button}
                />
                <ContentLock.Login.Footer {...stableLoginFooterProps}>
                  {content?.login?.footer ?? (
                    <Link href={signUpUrl} options={{ rel: 'nofollow' }} content="Eller skapa ditt konto gratis idag" />
                  )}
                </ContentLock.Login.Footer>
              </>
            )}
          </ContentLock.Login>
        </Tracking>
      )}

      {accessLevel === 'purchase' && <ContentLock.Purchase>{content?.purchase}</ContentLock.Purchase>}
    </ContentLock>
  );
};
