import { tw } from '@/utils/tw';
import BaseAuthorsListTheme from 'base/components/AuthorsList/theme';

const ListItemImage = tw.theme({
  extend: BaseAuthorsListTheme.ListItemImage,
  slots: {
    base: ['bg-transparent', 'rounded-sm'],
  },
});

const ListItemIcon = tw.theme({
  extend: BaseAuthorsListTheme.ListItemIcon,
  defaultVariants: {
    size: 'extraLarge',
  },
});

const ArticleListTheme = tw.theme({
  extend: BaseAuthorsListTheme,
  slots: {
    headline: ['text-headline-sm', 'my-3'],
    description: ['hidden'],
    list: ['gap-6', 'mb-6', 'grid', 'grid-cols-1', 'md:grid-cols-2', 'lg:grid-cols-3'],
    listItem: ['[&_svg]:text-logga-500', 'rounded-sm', 'md:max-w-[480px]', 'w-full'],
    listItemTitle: ['text-headline-xs'],
  },
  variants: {
    colors: {
      default: {
        listItem: ['bg-yellow-600'],
      },
    },
  },
});

export default Object.assign(ArticleListTheme, {
  ListItemLink: BaseAuthorsListTheme.ListItemLink,
  ListItemImage,
  ListItemIcon,
});
