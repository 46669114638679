import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode } from 'react';
import { DigitalSubscriptionDetailsHistoryItem } from './DigitalSubscriptionHistoryItem';

export interface StandaloneSubscriptionDetailsDigitalHistoryItemProps
  extends StandaloneComponentProps<typeof DigitalSubscriptionDetailsHistoryItem> {
  date?: ReactNode;
  description?: ReactNode;
  price?: ReactNode;
  invoiceLink?: ComponentProps<typeof DigitalSubscriptionDetailsHistoryItem.InvoiceLink>;
}

export const StandaloneSubscriptionDetailsDigitalHistoryItem: StandaloneComponent<
  StandaloneSubscriptionDetailsDigitalHistoryItemProps
> = ({ date, description, price, invoiceLink, ...props }) => {
  return (
    <DigitalSubscriptionDetailsHistoryItem {...props}>
      {date && <DigitalSubscriptionDetailsHistoryItem.Date>{date}</DigitalSubscriptionDetailsHistoryItem.Date>}
      {description && (
        <DigitalSubscriptionDetailsHistoryItem.Description>
          {description}
        </DigitalSubscriptionDetailsHistoryItem.Description>
      )}
      {price && <DigitalSubscriptionDetailsHistoryItem.Price>{price}</DigitalSubscriptionDetailsHistoryItem.Price>}
      {invoiceLink && (
        <DigitalSubscriptionDetailsHistoryItem.InvoiceLink
          {...mergeProps(invoiceLink, { options: props.options?.$invoiceLink })}
        />
      )}
    </DigitalSubscriptionDetailsHistoryItem>
  );
};
