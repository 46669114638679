import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps, MouseEvent, MouseEventHandler, ReactNode } from 'react';
import { useToggle } from 'react-use';
import { CancelSubscription } from './CancelSubscription';

interface CancelSubscriptionProps extends StandaloneComponentProps<typeof CancelSubscription> {
  headline?: ReactNode;
  description?: ReactNode;
  button?: ComponentProps<typeof CancelSubscription.Button>;
  link?: ComponentProps<typeof CancelSubscription.Link>;
  form?: ComponentProps<typeof CancelSubscription.Form>;
  onCancelSubscription?: MouseEventHandler<HTMLButtonElement>;
}

export const StandaloneCancelSubscription: StandaloneComponent<CancelSubscriptionProps> = ({
  headline,
  description,
  button,
  link,
  form,
  onCancelSubscription,
  ...props
}) => {
  const [isFormOpen, toggleIsFormOpen] = useToggle(false);

  if (isFormOpen && form) {
    return <CancelSubscription.Form {...mergeProps(form, { options: props.options?.$form })} />;
  }

  return (
    <CancelSubscription {...props}>
      {headline && <CancelSubscription.Headline>{headline}</CancelSubscription.Headline>}
      {description && <CancelSubscription.Description>{description}</CancelSubscription.Description>}
      {(button || link) && (
        <CancelSubscription.ActionsGroup>
          {button && (
            <CancelSubscription.Button
              {...mergeProps(button, {
                options: props.options?.$button,
                onClick: (event: MouseEvent<HTMLButtonElement>) => {
                  // TODO: handle cancel request
                  toggleIsFormOpen();
                  onCancelSubscription?.(event);
                },
              })}
            />
          )}
          {link && <CancelSubscription.Link {...mergeProps(link, { options: props.options?.$link })} />}
        </CancelSubscription.ActionsGroup>
      )}
    </CancelSubscription>
  );
};
