import { isNumber, isString } from 'typesafe-utils';
import { cn } from '../cn';

type WidthConfig = { desktop?: number | string; mobile?: number | string };

const isWidthMatchingSpan = (width?: number | string) => (span: number) => {
  return Math.round(isString(width) || isNumber(width) ? Number(width) : 100) === Math.round((100 / 12) * span);
};

export const withGridSystem = (config?: WidthConfig) => {
  const isSpanMobile = isWidthMatchingSpan(config?.mobile);
  const isSpanDesktop = isWidthMatchingSpan(config?.desktop);

  const className = cn(
    {
      'col-span-1': isSpanMobile(1),
      'col-span-2': isSpanMobile(2),
      'col-span-3': isSpanMobile(3),
      'col-span-4': isSpanMobile(4),
      'col-span-5': isSpanMobile(5),
      'col-span-6': isSpanMobile(6),
      'col-span-7': isSpanMobile(7),
      'col-span-8': isSpanMobile(8),
      'col-span-9': isSpanMobile(9),
      'col-span-10': isSpanMobile(10),
      'col-span-11': isSpanMobile(11),
      'col-span-12': isSpanMobile(12) || isSpanMobile(0),
    },
    {
      'md:col-span-1': isSpanDesktop(1),
      'md:col-span-2': isSpanDesktop(2),
      'md:col-span-3': isSpanDesktop(3),
      'md:col-span-4': isSpanDesktop(4),
      'md:col-span-5': isSpanDesktop(5),
      'md:col-span-6': isSpanDesktop(6),
      'md:col-span-7': isSpanDesktop(7),
      'md:col-span-8': isSpanDesktop(8),
      'md:col-span-9': isSpanDesktop(9),
      'md:col-span-10': isSpanDesktop(10),
      'md:col-span-11': isSpanDesktop(11),
      'md:col-span-12': isSpanDesktop(12) || isSpanMobile(0),
    },
  );

  return className;
};
