import { AuthorsListTheme } from '@/components/AuthorsList/theme';
import { Icon as StandaloneIcon } from '@/components/Icon';
import { Image as StandaloneImage } from '@/components/Image';
import { Link as StandaloneLink } from '@/components/Link';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: AuthorsListTheme });

const Base = $({ as: 'section', slot: 'base' });
const Headline = $({ as: 'h1', slot: 'headline' });
const Description = $({ as: 'p', slot: 'description' });
const List = $({ as: 'ul', slot: 'list' });
const ListItem = $({ as: 'li', slot: 'listItem' });
const ListItemTitle = $({ as: 'h2', slot: 'listItemTitle' });
const ListItemImage = GenericSlot({ as: StandaloneImage, theme: AuthorsListTheme.ListItemImage });
const ListItemLink = GenericSlot({ as: StandaloneLink, theme: AuthorsListTheme.ListItemLink });
const ListItemIcon = GenericSlot({ as: StandaloneIcon, theme: AuthorsListTheme.ListItemIcon });

export default Object.assign(Base, {
  Headline,
  Description,
  List,
  ListItem,
  ListItemTitle,
  ListItemImage,
  ListItemLink,
  ListItemIcon,
});
