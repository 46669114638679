import type { OptionalLinkProps } from '@/components/OptionalLink';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { ComponentProps } from 'react';

import { Image } from '@/components/Image';
import { OptionalLink } from '@/components/OptionalLink';
import { mergeProps } from '@/utils/merge';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/sv';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { Teaser } from './Teaser';

export interface StandaloneTeaserProps extends StandaloneComponentProps<typeof Teaser> {
  cta?: React.ReactNode;
  date?: Date | React.ReactNode;
  kicker?: React.ReactNode;
  link?: {
    cta?: OptionalLinkProps;
    date?: OptionalLinkProps;
    kicker?: OptionalLinkProps;
    media?: OptionalLinkProps;
    sticker?: OptionalLinkProps;
    tag?: OptionalLinkProps;
    teaser?: OptionalLinkProps;
    ticker?: OptionalLinkProps;
    title?: OptionalLinkProps;
  };
  media?: React.ReactNode;
  sticker?: React.ReactNode;
  tag?: React.ReactNode;
  ticker?: ComponentProps<typeof Teaser.Ticker>;
  title?: React.ReactNode;
}

export const StandaloneTeaser: StandaloneComponent<StandaloneTeaserProps> = ({
  cta,
  date,
  kicker,
  link,
  media,
  sticker,
  tag,
  ticker,
  title,
  ...props
}) => {
  const format = getOcelotConfig('common.format.date');

  return (
    <Teaser {...mergeProps({ options: { ticker: Boolean(ticker) } }, props)}>
      {ticker && (
        <Teaser.Ticker
          {...mergeProps({ options: props.options?.$ticker, link: link?.ticker ?? link?.teaser }, ticker)}
        />
      )}
      <Teaser.Media>
        <OptionalLink {...(link?.media ?? link?.teaser)}>{media || <Image alt="fallback" />}</OptionalLink>
        {sticker && (
          <Teaser.Sticker>
            <OptionalLink {...(link?.sticker ?? link?.teaser)}>{sticker}</OptionalLink>
          </Teaser.Sticker>
        )}
      </Teaser.Media>
      <Teaser.Group>
        {tag && (
          <Teaser.Tag>
            <OptionalLink {...(link?.tag ?? link?.teaser)}>{tag}</OptionalLink>
          </Teaser.Tag>
        )}
        {title && (
          <Teaser.Title>
            <OptionalLink {...(link?.title ?? link?.teaser)}>{title}</OptionalLink>
          </Teaser.Title>
        )}
        {date && (
          <Teaser.Date>
            <OptionalLink {...(link?.date ?? link?.teaser)}>
              {date instanceof Date ? dayjs(date).locale(locale).format(format) : date}
            </OptionalLink>
          </Teaser.Date>
        )}
        {kicker && (
          <Teaser.Kicker>
            <OptionalLink {...(link?.kicker ?? link?.teaser)}>{kicker}</OptionalLink>
          </Teaser.Kicker>
        )}
        {cta && (
          <Teaser.Cta>
            <OptionalLink {...(link?.cta ?? link?.teaser)}>{cta}</OptionalLink>
          </Teaser.Cta>
        )}
      </Teaser.Group>
    </Teaser>
  );
};
