/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorSlideShow as ResolvedLabradorSlideShow } from 'base/components/labrador/SlideShow';

export const LabradorSlideShow: typeof ResolvedLabradorSlideShow = withLabradorInstanceof((props) => {
    return <ResolvedLabradorSlideShow {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorSlideShowProps = PropsFromComponent<typeof LabradorSlideShow>;
