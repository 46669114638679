import { Blip as StandaloneBlip } from '@/components/Blip';
import { Button as StandaloneButton } from '@/components/Button';
import { DirektTeaserTheme } from '@/components/DirektTeaser/theme';
import { Link as StandaloneLink } from '@/components/Link';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: DirektTeaserTheme });

const Base = $({ as: 'section', slot: 'base' });
const Headline = $({ as: 'header', slot: 'headline' });
const Header = $({ as: 'h3', slot: 'header' });
const List = $({ as: 'ul', slot: 'list' });
const Item = $({ as: 'li', slot: 'item' });
const Time = $({ as: 'time', slot: 'time' });
const ItemText = $({ as: 'h4', slot: 'itemText' });
const Blip = GenericSlot({ as: StandaloneBlip, theme: DirektTeaserTheme.Blip });
const ItemLink = GenericSlot({ as: StandaloneLink, theme: DirektTeaserTheme.Link });
const Button = GenericSlot({ as: StandaloneButton, theme: DirektTeaserTheme.Button });

export const DirektTeaser = Object.assign(Base, {
  Headline,
  Header,
  List,
  Item,
  Time,
  ItemText,
  Blip,
  ItemLink,
  Button,
});
