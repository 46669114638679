import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode } from 'react';
import { useToggle } from 'react-use';
import { DigitalSubscriptionDetails } from './Digital';

export interface StandaloneSubscriptionDetailsDigitalProps
  extends StandaloneComponentProps<typeof DigitalSubscriptionDetails> {
  headline?: ReactNode;
  description?: ReactNode;
  detailsList?: ReactNode[];
  contactInfo?: ReactNode;
  buttonLabels?: {
    cancelSubscription?: ReactNode;
    paymentHistory?: ReactNode;
  };
  buttonOptions?: {
    cancelSubscription?: ComponentProps<typeof DigitalSubscriptionDetails.Button>['options'];
    paymentHistory?: ComponentProps<typeof DigitalSubscriptionDetails.Button>['options'];
  };
  history?: ComponentProps<typeof DigitalSubscriptionDetails.History>;
}

export const StandaloneSubscriptionDetailsDigital: StandaloneComponent<StandaloneSubscriptionDetailsDigitalProps> = ({
  headline,
  description,
  detailsList,
  contactInfo,
  buttonLabels,
  buttonOptions,
  history,
  ...props
}) => {
  const [isHistoryOpen, toggleIsHistoryOpen] = useToggle(false);
  return (
    <DigitalSubscriptionDetails {...props}>
      {headline && <DigitalSubscriptionDetails.Headline>{headline}</DigitalSubscriptionDetails.Headline>}
      {description && <DigitalSubscriptionDetails.Description>{description}</DigitalSubscriptionDetails.Description>}
      {Boolean(detailsList?.length) && (
        <DigitalSubscriptionDetails.DetailsList>
          {detailsList!.map((detail, index) => (
            <DigitalSubscriptionDetails.Detail key={index}>{detail}</DigitalSubscriptionDetails.Detail>
          ))}
        </DigitalSubscriptionDetails.DetailsList>
      )}
      {buttonLabels && (
        <DigitalSubscriptionDetails.ButtonGroup>
          {buttonLabels.cancelSubscription && (
            <DigitalSubscriptionDetails.Button
              options={mergeOptions(props.options?.$button, buttonOptions?.cancelSubscription)}
            >
              {buttonLabels.cancelSubscription}
            </DigitalSubscriptionDetails.Button>
          )}
          {buttonLabels.paymentHistory && (
            <DigitalSubscriptionDetails.Button
              options={mergeOptions(
                { colors: 'secondary', onClick: () => toggleIsHistoryOpen() },
                props.options?.$button,
                buttonOptions?.paymentHistory,
              )}
            >
              {buttonLabels.paymentHistory}
            </DigitalSubscriptionDetails.Button>
          )}
        </DigitalSubscriptionDetails.ButtonGroup>
      )}
      {contactInfo && <DigitalSubscriptionDetails.ContactInfo>{contactInfo}</DigitalSubscriptionDetails.ContactInfo>}
      {isHistoryOpen && history && (
        <DigitalSubscriptionDetails.History {...mergeProps(history, { options: props.options?.$history })} />
      )}
    </DigitalSubscriptionDetails>
  );
};
