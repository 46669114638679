/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTeaser as ResolvedStandaloneTeaser } from 'base/components/standalone/Teaser';

export const Teaser: typeof ResolvedStandaloneTeaser = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTeaser {...props} />
        </ErrorBoundary>
    );
});

export type TeaserProps = PropsFromComponent<typeof Teaser>;

/** @deprecated Use slot architecture instead */
export const TeaserWith = (extras: DynamicStandaloneExtras): typeof Teaser => {
    return function Teaser(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Teaser');
        return <ResolvedStandaloneTeaser {...mergeProps({ options: { theme } }, props)} />;
    }
}