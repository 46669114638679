import { tw } from '@/utils/tw';
import BaseContentLockTheme from 'base/components/ContentLock/theme';

const Button = tw.theme({
  extend: BaseContentLockTheme.Button,
  variants: {
    size: {
      large: 'my-0',
    },
  },
});

const ContentLockTheme = tw.theme({
  extend: BaseContentLockTheme,
  slots: {
    base: [
      'before:bottom-[calc(100%+1px)]',
      'border-logga-600',
      'border',
      'md:pb-7.5',
      'md:pt-12',
      'py-3',
      'rounded-3xl',
    ],
    login: ['gap-y-3', 'flex', 'flex-col', 'items-center', 'text-center', 'not-prose'],
    loginCaption: ['text-paragraph-md', 'pb-6', 'max-w-[460px]'],
    loginFooter: ['text-logga-600', 'text-paragraph-md', 'hover:underline'],
    loginHeadline: ['text-headline-sm', 'md:text-headline-md'],
  },
});

export default Object.assign(ContentLockTheme, { Button });
