import { LoopAroundTheme } from '@/components/LoopAround/theme';
import { tw } from '@/utils/tw';

const defaultVariants = { orientation: 'vertical' } as const;

const LoopAround = tw.theme({
  extend: LoopAroundTheme,
  variants: {
    orientation: {
      vertical: {
        base: [],
        reel: [],
      },
      horizontal: {
        base: ['col-span-2', 'md:grid-cols-7:col-start-2'],
        reel: [],
      },
    },
  },
  defaultVariants,
});

const Teaser = tw.theme({
  slots: {
    base: ['group/teaser', 'grid', 'grid-container', 'w-full'],
    cta: ['text-primary-600'],
    date: ['text-body-xs', 'text-gray-600'],
    group: ['flex', 'min-w-0', 'flex-col', 'gap-1', 'break-words'],
    kicker: ['text-body-xs'],
    media: ['relative'],
    sticker: ['absolute', 'bottom-grid-gap', 'right-grid-gap', 'uppercase', 'p-2', 'bg-primary-600', 'text-white'],
    tag: ['text-headline-xs', 'text-primary-600', 'uppercase'],
    title: ['text-headline-sm'],
  },
  variants: {
    orientation: {
      horizontal: {
        base: ['grid-cols-2', 'md:grid-rows-[auto_1fr]'],
        cta: [],
        date: [],
        group: ['ml-grid-gap-1/2'],
        kicker: [],
        media: ['md:grid-cols-7:row-span-2', 'md:grid-cols-7:row-start-1'],
        sticker: [],
        tag: [],
        title: [],
      },
      vertical: {
        base: ['auto-rows-min'],
        cta: [],
        date: [],
        group: ['mt-4'],
        kicker: [],
        media: [],
        sticker: [],
        tag: [],
        title: [],
      },
    },
    ticker: {
      true: {},
    },
  },
  compoundVariants: [
    {
      orientation: 'horizontal',
      ticker: true,
      class: {
        group: ['mt-grid-gap-1/2'],
      },
    },
  ],
  defaultVariants,
});

export default Object.assign(Teaser, { LoopAround });
