import { tw } from '@/utils/tw';

const VideoReelsTheme = tw.theme({
  slots: {
    base: 'max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg col-span-12 mb-8 mx-auto',
    element: 'w-full',
    heading: 'mb-0 text-headline-md',
  },
});

export default VideoReelsTheme;
