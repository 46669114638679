import { LinkTheme } from '@/components/Link/theme';
import { tw } from '@/utils/tw';

const DigitalSubscriptionHistoryItemTheme = tw.theme({
  slots: {
    base: [],
    date: [],
    description: [],
    price: [],
  },
});

export default Object.assign(DigitalSubscriptionHistoryItemTheme, { InvoiceLink: LinkTheme });
