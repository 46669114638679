import { Button as StandaloneButton } from '@/components/Button';
import { Link as StandaloneLink } from '@/components/Link';
import { SubscriptionDetailsDigitalHistoryItem } from '@/components/standalone/SubscriptionDetails/Digital/History/Item';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';
import SubscriptionDetailsDigitalHistoryTheme from './theme';

const $ = GenericSlotFactory({ theme: SubscriptionDetailsDigitalHistoryTheme });

const Base = $({ as: 'div', slot: 'base' });
const ActionsGroup = $({ as: 'div', slot: 'actionsGroup' });
const List = $({ as: 'ul', slot: 'list' });
const Headline = $({ as: 'h4', slot: 'headline' });
const Item = GenericSlot({
  as: SubscriptionDetailsDigitalHistoryItem,
  theme: SubscriptionDetailsDigitalHistoryTheme.Item,
});
const Button = GenericSlot({ as: StandaloneButton, theme: SubscriptionDetailsDigitalHistoryTheme.Button });
const Link = GenericSlot({ as: StandaloneLink, theme: SubscriptionDetailsDigitalHistoryTheme.Link });

export const DigitalSubscriptionDetailsHistory = Object.assign(Base, {
  ActionsGroup,
  Button,
  Headline,
  List,
  Item,
  Link,
});
