import { Button as StandaloneButton } from '@/components/Button';
import { ContentLockTheme } from '@/components/ContentLock/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ContentLockTheme });

const Base = $({ as: 'section', slot: 'base' });
const Login = $({ as: 'div', slot: 'login' });
const LoginCaption = $({ as: 'p', slot: 'loginCaption' });
const LoginFooter = $({ as: 'p', slot: 'loginFooter' });
const LoginHeadline = $({ as: 'h3', slot: 'loginHeadline' });
const Purchase = $({ as: 'div', slot: 'purchase' });
const Button = GenericSlot({ as: StandaloneButton, theme: ContentLockTheme.Button });

export const ContentLock = Object.assign(Base, {
  Login: Object.assign(Login, {
    Button,
    Caption: LoginCaption,
    Footer: LoginFooter,
    Headline: LoginHeadline,
  }),
  Purchase,
});
