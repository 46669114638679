import { RecommendedArticles } from '@/components/RecommendedArticles';
import { Strossle } from '@/components/Strossle';
import { useAppState } from '@/hooks/useAppState';
import { useClassName } from '@/styles/Article';
import { Device } from '@/types/app';
import { Component, ComponentProps } from '@/types/component';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { mergeProps } from '@/utils/merge';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { findFirstOfType } from 'lib/labrador/content-operations/findBy';
import { useEffect, useState } from 'react';
import { RecommendedArticlesData } from '../RecommendedArticles';

type RecommendationSectionElement = 'recommendedArticles' | 'strossle' | 'affiliateGallery' | 'moreRecommendedArticles';

const RECOMMENDATION_SECTION_ELEMENTS_FALLBACK: RecommendationSectionElement[] = [
  'recommendedArticles',
  'strossle',
  'affiliateGallery',
  'moreRecommendedArticles',
];

const getRecommendationSectionElements = (): RecommendationSectionElement[] => {
  // in future it will be ordered dynamically based on user preferences
  return RECOMMENDATION_SECTION_ELEMENTS_FALLBACK;
};

const getRecommendedArticles = async (articleIds: string[], device: Device) => {
  const responses = await Promise.all(
    articleIds.map((articleId) => {
      const url = getUrl('/api/page')!;
      const params = new URLSearchParams({
        route: `article/${articleId}`,
        device,
      });
      url.search = params.toString();

      return http.get(url.href);
    }),
  );

  const articles = responses.reduce<RecommendedArticlesData>((acc, response) => {
    if (!response?.data) return acc;

    const { title, subtitle, publishedUrl, category } = response?.data?.data ?? {};
    const articleHeader = findFirstOfType(response?.data?.children ?? [], 'articleHeader');
    const articleImage = findFirstOfType(articleHeader?.children ?? [], 'labradorImage');
    const imageSrc = articleImage?.data?.imageUrl?.[device];
    const imageAlt = articleImage?.data?.alt;

    return [
      ...acc,
      {
        headline: title,
        description: subtitle,
        caption: category,
        image: {
          src: imageSrc,
          alt: imageAlt,
        },
        links: {
          article: { href: publishedUrl },
        },
      },
    ];
  }, []);

  return articles;
};

export interface ArticleRecommendedProps extends ComponentProps<'div'> {
  affiliateGallery?: JSX.Element;
}

export const ArticleRecommendedComponent: Component<ArticleRecommendedProps> = ({ affiliateGallery, ...props }) => {
  const [{ recommendedArticles, device }] = useAppState();
  const [recommendedArticleTeasers, setRecommendedArticleTeasers] = useState<RecommendedArticlesData | undefined>(
    undefined,
  );

  const elements = getRecommendationSectionElements();

  useEffect(() => {
    const articleIds = recommendedArticles.map((article) => article.article_id);
    if (
      recommendedArticleTeasers?.length ||
      !getOcelotConfig('features.recommendedArticles.enabled') ||
      !articleIds?.length
    )
      return;

    getRecommendedArticles(articleIds, device).then((articles) => {
      setRecommendedArticleTeasers(articles);
    });
  }, [recommendedArticles]);

  const components = elements
    .map((elementType) => {
      switch (elementType) {
        case 'recommendedArticles':
          return (
            <RecommendedArticles
              key="recommendedArticles"
              isLoading={!recommendedArticleTeasers}
              articles={recommendedArticleTeasers}
            />
          );
        case 'strossle':
          return <Strossle key="strossle" />;
        case 'affiliateGallery':
          return affiliateGallery && { ...affiliateGallery, key: 'affiliateGallery' };
        case 'moreRecommendedArticles':
          return <div key="moreRecommendedArticles" id="more-recommended-articles-portal" />;
        default:
          return undefined;
      }
    })
    .filter(Boolean) as JSX.Element[];

  const className = useClassName('recommended', props);

  return <div {...mergeProps({ className }, props)}>{components}</div>;
};
