import { Accordion as StandaloneAccordion } from '@/components/Accordion';
import { SubscriptionDetailsTheme } from '@/components/SubscriptionDetails/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: SubscriptionDetailsTheme });

const Base = $({ as: 'div', slot: 'base' });
const AccordionGroup = $({ as: 'div', slot: 'accordionGroup' });
const Accordion = GenericSlot({ as: StandaloneAccordion, theme: SubscriptionDetailsTheme.Accordion });

export default Object.assign(Base, { AccordionGroup, Accordion });
