import { ButtonTheme } from '@/components/Button/theme';
import { tw } from '@/utils/tw';

const Button = tw.theme({
  extend: ButtonTheme,
  variants: {
    size: {
      large: 'my-4',
    },
  },
});

const ContentLockTheme = tw.theme({
  slots: {
    base: [
      'before:absolute',
      'before:bg-gradient-to-t',
      'before:bottom-full',
      'before:from-white',
      'before:h-69',
      'before:left-0',
      'before:w-full',
      'py-9',
      'relative',
      'w-full',
    ],
    purchase: [],
    login: ['flex', 'flex-col', 'items-center', 'text-center', 'gap-y-2'],
    loginCaption: ['text-body-md'],
    loginFooter: ['text-headline-sm'],
    loginHeadline: ['text-headline-md'],
  },
});

export default Object.assign(ContentLockTheme, { Button });
