import { ButtonProps } from '@/components/Button';
import { TimerProps } from '@/components/Timer';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ReactNode } from 'react';
import { Confirmation } from './Confirmation';

interface ConfirmationBannerProps {
  header?: ReactNode;
  content?: ReactNode;
}
export interface StandaloneConfirmationProps extends StandaloneComponentProps<typeof Confirmation> {
  title?: ReactNode;
  banner?: ConfirmationBannerProps;
  content?: ReactNode;
  timer?: TimerProps;
  button?: ButtonProps;
}

export const StandaloneConfirmation: StandaloneComponent<StandaloneConfirmationProps> = ({
  title,
  banner,
  button,
  content,
  timer,
  ...props
}) => {
  return (
    <Confirmation {...props}>
      {title && <Confirmation.Title>{title}</Confirmation.Title>}
      <Confirmation.Banner>
        {banner?.header && <Confirmation.Banner.Header>{banner?.header}</Confirmation.Banner.Header>}
        {banner?.content}
      </Confirmation.Banner>
      {content && <Confirmation.Content>{content}</Confirmation.Content>}
      {timer && <Confirmation.Timer {...mergeProps(props?.options?.$timer, timer)} />}
      {button && <Confirmation.Button {...mergeProps(props?.options?.$button, button)} />}
    </Confirmation>
  );
};
