/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneSubscriptionDetailsDigitalHistory as ResolvedStandaloneSubscriptionDetailsDigitalHistory } from 'base/components/standalone/SubscriptionDetails/Digital/History';

export const SubscriptionDetailsDigitalHistory: typeof ResolvedStandaloneSubscriptionDetailsDigitalHistory = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneSubscriptionDetailsDigitalHistory {...props} />
        </ErrorBoundary>
    );
});

export type SubscriptionDetailsDigitalHistoryProps = PropsFromComponent<typeof SubscriptionDetailsDigitalHistory>;

/** @deprecated Use slot architecture instead */
export const SubscriptionDetailsDigitalHistoryWith = (extras: DynamicStandaloneExtras): typeof SubscriptionDetailsDigitalHistory => {
    return function SubscriptionDetailsDigitalHistory(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SubscriptionDetailsDigitalHistory');
        return <ResolvedStandaloneSubscriptionDetailsDigitalHistory {...mergeProps({ options: { theme } }, props)} />;
    }
}