import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { ComponentProps, ReactNode } from 'react';
import AuthorsList from './AuthorsList';

export interface AuthorsListAuthor {
  icon?: ComponentProps<typeof AuthorsList.ListItemIcon>;
  image?: ComponentProps<typeof AuthorsList.ListItemImage>;
  link?: ComponentProps<typeof AuthorsList.ListItemLink>;
  title?: ReactNode;
}

export interface StandaloneAuthorsListProps extends StandaloneComponentProps<typeof AuthorsList> {
  authors?: AuthorsListAuthor[];
  description?: ReactNode;
  headline?: ReactNode;
}

export const StandaloneAuthorsList: StandaloneComponent<StandaloneAuthorsListProps> = ({
  headline,
  description,
  authors,
  ...props
}) => {
  return (
    <AuthorsList {...props}>
      {headline && <AuthorsList.Headline>{headline}</AuthorsList.Headline>}
      {description && <AuthorsList.Description>{description}</AuthorsList.Description>}
      {authors && Boolean(authors.length) && (
        <AuthorsList.List>
          {authors.map(({ icon, image, link, title }, index) => (
            <AuthorsList.ListItem key={index}>
              <AuthorsList.ListItemLink
                {...link}
                options={mergeOptions(props.options?.$listItemLink, link?.options)}
                content={
                  <>
                    {image && (
                      <AuthorsList.ListItemImage
                        {...image}
                        options={mergeOptions(props.options?.$listItemImage, image.options)}
                      />
                    )}
                    {title && <AuthorsList.ListItemTitle>{title}</AuthorsList.ListItemTitle>}
                    {icon && (
                      <AuthorsList.ListItemIcon
                        {...icon}
                        options={mergeOptions(props.options?.$listItemIcon, icon.options)}
                      />
                    )}
                  </>
                }
              />
            </AuthorsList.ListItem>
          ))}
        </AuthorsList.List>
      )}
    </AuthorsList>
  );
};
