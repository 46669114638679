import { IconProps } from '@/components/Icon';
import { Link } from '@/components/Link';
import { Spinner } from '@/components/Spinner';
import { getCategoryUrl } from '@/utils/getCategoryUrl';
import { getDomain } from '@/utils/getDomain';
import { getImageServer } from '@/utils/getImageServer';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { getSavedArticlesIds, getUserTags, removeSavedArticle } from 'lib/alltIdMyData';
import { slugifySection } from 'lib/labrador/utils';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import type { StandaloneComponent, StandaloneComponentProps } from 'types/component';
import { SavedArticles } from './SavedArticles';

export interface Article {
  image: string;
  category: string;
  categoryUrl: string;
  title: string;
  subtitle: string;
  publishedUrl: string;
  id: string;
  isLocked?: boolean;
}

export interface StandaloneSavedArticlesProps extends StandaloneComponentProps<typeof SavedArticles> {
  headline?: ReactNode;
  articles: Article[];
  button?: ReactNode;
  icon?: IconProps['name'];
  initialArticlesCount?: number;
  emptyFallback?: ReactNode;
}

const getArticlesSortingOrder = (idsObject: { [id: string]: number }) =>
  Object.entries(idsObject ?? {})
    .sort(([, saveTime1], [, saveTime2]) => saveTime2 - saveTime1)
    .map(([articleId]) => articleId);

const sortArticles = (articles: { id: string }[], order: string[]) => {
  const sortedIndexArray = Object.keys(articles ?? {}).sort(
    (index1, index2) => order.indexOf(articles[+index1].id) - order.indexOf(articles[+index2].id),
  );
  return sortedIndexArray.map((index) => articles[+index]);
};

export const StandaloneSavedArticles: StandaloneComponent<StandaloneSavedArticlesProps> = ({
  headline,
  articles,
  button,
  icon,
  initialArticlesCount = 8,
  emptyFallback,
  ...props
}) => {
  const [savedArticles, setSavedArticles] = useState(articles ?? []);
  const [showArticlesCount, setShowArticlesCount] = useState(Math.min(articles.length, initialArticlesCount));
  const [isLoading, setIsLoading] = useState(false);

  const fetchFunc = useCallback(async () => {
    setIsLoading(true);
    const { data: savedArticlesIdsResponse } = (await getSavedArticlesIds()) ?? {};
    const { response: savedArticlesIdsData } = savedArticlesIdsResponse ?? {};
    const savedArticlesIds = Object.keys(savedArticlesIdsData ?? {});

    if (!savedArticlesIdsData || !savedArticlesIds.every(Number)) {
      logger.error('Saved articles response is not valid', savedArticlesIdsResponse);
      setIsLoading(false);
      return;
    }

    const { data: fetchedArticles } =
      (await http.get('/api/articles/saved', {
        params: {
          ids: savedArticlesIds.join(),
        },
      })) ?? {};

    if (!fetchedArticles) {
      logger.error('Fetched articles response is missing', fetchedArticles);
      setIsLoading(false);
      return;
    }

    const articlesSortingOrder = getArticlesSortingOrder(savedArticlesIdsData);
    const sortedArticles = sortArticles(fetchedArticles, articlesSortingOrder);

    setIsLoading(false);

    const savedArticles = sortedArticles.reduce((result, article) => {
      const {
        id,
        published_url,
        section_tag,
        title,
        subtitle,
        image,
        used_image_ids_json,
        isNativeAd,
        nativeAdLabel,
        prominentTag,
        requiredPurchase,
      } = article as any;
      const imageId = image ?? (used_image_ids_json ? JSON.parse(used_image_ids_json)[0] : undefined);
      const imageUrl = getUrl(published_url, getImageServer());
      const searchParams = new URLSearchParams({ imageId, width: '500' });
      const categoryUrl = getUrl(slugifySection(section_tag), getDomain());

      if (imageId && imageUrl) {
        imageUrl.search = searchParams.toString();

        result.push({
          id,
          publishedUrl: published_url,
          category: section_tag,
          categoryUrl: getCategoryUrl(
            { category: section_tag, categoryUrl, prominentTag },
            { isNativeAd, nativeAdLabel },
          ),
          title,
          subtitle,
          image: imageUrl.href,
          isLocked: Boolean(requiredPurchase && !getUserTags().includes(requiredPurchase)),
        });
      }

      return result;
    }, [] as any[]);

    setSavedArticles(savedArticles);

    if (!articles.length) {
      setShowArticlesCount(Math.min(savedArticles.length, initialArticlesCount));
    }
  }, [articles.length, initialArticlesCount]);

  useEffect(() => {
    fetchFunc();
  }, [fetchFunc]);

  const showMoreArticles = () => {
    setShowArticlesCount((prevState) => prevState + 4);
  };

  const removeArticle = async (articleId: string) => {
    setSavedArticles((prevState) => prevState.filter((article) => article.id !== articleId));
    await removeSavedArticle({ articleId });
  };

  const getSavedArticlesContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (!savedArticles.length) {
      return (
        <SavedArticles.Empty>
          {emptyFallback ?? (
            <>
              <span>
                Du har inga sparade artiklar än, för att spara en artikel så klickar du på knappen “spara artikel” i en
                artikel.
              </span>
              <span>
                Besök{' '}
                <a href="/" className="font-bold hover:underline">
                  vår startsida
                </a>{' '}
                för att hitta artiklar du gillar.
              </span>
            </>
          )}
        </SavedArticles.Empty>
      );
    }

    return savedArticles
      .slice(0, showArticlesCount)
      .map(({ title, category, subtitle, publishedUrl, image, id, categoryUrl, isLocked }) => (
        <SavedArticles.Article key={title} data-locked={isLocked ? '' : undefined}>
          <SavedArticles.ArticleImage src={image} alt={title} />
          <SavedArticles.ArticleHeader>
            <SavedArticles.ArticleTag value={category} link={{ href: categoryUrl }} />
            <SavedArticles.ArticleIcon name={icon ?? 'bin'} options={{ onClick: () => removeArticle(id) }} />
          </SavedArticles.ArticleHeader>
          <Link
            href={!isLocked ? publishedUrl : undefined}
            content={
              <>
                <SavedArticles.ArticleCaption>{title}</SavedArticles.ArticleCaption>
                <SavedArticles.ArticleDescription>{subtitle}</SavedArticles.ArticleDescription>
              </>
            }
          />
        </SavedArticles.Article>
      ));
  };

  return (
    <SavedArticles {...props}>
      <SavedArticles.Headline>{headline ?? 'Mina sparade artiklar'}</SavedArticles.Headline>
      <SavedArticles.Wrapper>{getSavedArticlesContent()}</SavedArticles.Wrapper>
      {savedArticles.length > showArticlesCount && (
        <SavedArticles.Button
          content={button ?? 'Visa fler'}
          options={{
            size: 'large',
            onClick: showMoreArticles,
          }}
        />
      )}
    </SavedArticles>
  );
};
