import { SubscriptionDetailsDigitalHistoryItem } from '@/components/standalone/SubscriptionDetails/Digital/History/Item';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode } from 'react';
import { DigitalSubscriptionDetailsHistory } from './History';

interface StandaloneSubscriptionDetailsDigitalHistoryProps
  extends StandaloneComponentProps<typeof DigitalSubscriptionDetailsHistory> {
  headline?: ReactNode;
  items?: ComponentProps<typeof SubscriptionDetailsDigitalHistoryItem>[];
  button?: ComponentProps<typeof DigitalSubscriptionDetailsHistory.Button>;
  link?: ComponentProps<typeof DigitalSubscriptionDetailsHistory.Link>;
}

export const StandaloneSubscriptionDetailsDigitalHistory: StandaloneComponent<
  StandaloneSubscriptionDetailsDigitalHistoryProps
> = ({ items, button, link, headline, ...props }) => {
  return (
    <DigitalSubscriptionDetailsHistory {...props}>
      {headline && <DigitalSubscriptionDetailsHistory.Headline>{headline}</DigitalSubscriptionDetailsHistory.Headline>}
      {Boolean(items?.length) && (
        <DigitalSubscriptionDetailsHistory.List>
          {items!.map((item, index) => (
            <DigitalSubscriptionDetailsHistory.Item
              key={index}
              {...mergeProps(item, { options: props.options?.$item })}
            />
          ))}
        </DigitalSubscriptionDetailsHistory.List>
      )}
      {(button || link) && (
        <DigitalSubscriptionDetailsHistory.ActionsGroup>
          {button && (
            <DigitalSubscriptionDetailsHistory.Button {...mergeProps(button, { options: props.options?.$button })} />
          )}
          {link && <DigitalSubscriptionDetailsHistory.Link {...mergeProps(link, { options: props.options?.$link })} />}
        </DigitalSubscriptionDetailsHistory.ActionsGroup>
      )}
    </DigitalSubscriptionDetailsHistory>
  );
};
