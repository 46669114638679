import { AccordionTheme } from '@/components/Accordion/theme';
import { tw } from '@/utils/tw';

const Accordion = tw.theme({
  extend: AccordionTheme,
  defaultVariants: {
    type: 'rich',
  },
});

const SubscriptionDetailsTheme = tw.theme({
  slots: {
    base: [],
    accordionGroup: ['flex', 'flex-col', 'gap-4'],
  },
});

export default Object.assign(SubscriptionDetailsTheme, { Accordion });
