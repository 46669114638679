import { Icon } from '@/components/Icon';
import { useAppState } from '@/hooks/useAppState';
import { ComponentProps, StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { useSavedArticles } from 'lib/hooks/useSavedArticles';
import { ReactNode, useRef } from 'react';
import { SaveArticle } from './SaveArticle';

export interface StandaloneSaveArticleProps extends StandaloneComponentProps<typeof SaveArticle> {
  save?: ComponentProps<typeof SaveArticle.Button>;
  loose?: ComponentProps<typeof SaveArticle.Button>;
  modal?: ReactNode;
}

export const StandaloneSaveArticle: StandaloneComponent<StandaloneSaveArticleProps> = ({
  save,
  loose,
  modal,
  ...props
}) => {
  const [{ pageId }] = useAppState();
  const modalRef = useRef<HTMLDialogElement>(null);
  const [isArticleSaved, toggleSaveArticle] = useSavedArticles({
    articleId: String(pageId),
    notLoggedInCallback: () => modalRef.current?.showModal(),
  });

  const defaultSave = (
    <>
      <Icon name="bookmark" options={{ size: 'medium' }} />
      Spara artikel
    </>
  );
  const defaultLoose = (
    <>
      <Icon name="bookmarkAdded" options={{ size: 'medium' }} />
      Sparad artikel
    </>
  );

  return (
    <SaveArticle {...props}>
      {isArticleSaved ? (
        <SaveArticle.Button
          {...mergeProps(
            { options: { onClick: toggleSaveArticle }, content: defaultLoose },
            { options: props?.options?.$button },
            loose,
          )}
        />
      ) : (
        <SaveArticle.Button
          {...mergeProps(
            { options: { onClick: toggleSaveArticle }, content: defaultSave },
            { options: props?.options?.$button },
            save,
          )}
        />
      )}

      {modal && (
        <SaveArticle.Modal ref={modalRef} onClick={() => modalRef.current?.close()}>
          {modal}
        </SaveArticle.Modal>
      )}
    </SaveArticle>
  );
};
