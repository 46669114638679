import { ButtonTheme } from '@/components/Button/theme';
import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const CloseButton = tw.theme({
  extend: ButtonTheme,
  base: ['absolute', 'right-3', 'top-3', 'max-h-8', 'w-8', 'rounded-full', 'px-0', 'py-0'],
});

const CloseIcon = tw.theme({
  extend: IconTheme,
  base: ['fill-white', 'mx-auto'],
  defaultVariants: {
    size: 'medium',
  },
});

const DialogIcon = tw.theme({
  extend: IconTheme,
  base: [],
  defaultVariants: {
    size: 'large',
  },
});

const DialogTheme = tw.theme({
  slots: {
    base: [
      'backdrop:bg-black/60',
      'scale-0',
      'open:scale-100',
      'transition-all',
      'open:w-91',
      'open:md:w-[632px]',
      'bg-gray-100',
      'px-3',
      'md:px-6',
      'pb-3',
      'md:pb-12',
      'pt-17',
      'z-50',
      'flex',
      'flex-col',
      'items-center',
      'gap-3',
      'rounded',
    ],
    buttonGroup: [
      'flex',
      'justify-center',
      'w-full',
      'gap-3',
      'md:[&>button]:max-w-1/2',
      '[&>button]:grow',
      'mt-3',
      'md:mt-6',
    ],
    headline: ['text-center', 'text-headline-md'],
    content: ['text-center'],
  },
});

export default Object.assign(DialogTheme, { CloseIcon, DialogIcon, Button: ButtonTheme, CloseButton });
