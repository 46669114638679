/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneCancelSubscriptionSubscriptionCancelled as ResolvedStandaloneCancelSubscriptionSubscriptionCancelled } from 'base/components/standalone/CancelSubscription/SubscriptionCancelled';

export const CancelSubscriptionSubscriptionCancelled: typeof ResolvedStandaloneCancelSubscriptionSubscriptionCancelled = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneCancelSubscriptionSubscriptionCancelled {...props} />
        </ErrorBoundary>
    );
});

export type CancelSubscriptionSubscriptionCancelledProps = PropsFromComponent<typeof CancelSubscriptionSubscriptionCancelled>;

/** @deprecated Use slot architecture instead */
export const CancelSubscriptionSubscriptionCancelledWith = (extras: DynamicStandaloneExtras): typeof CancelSubscriptionSubscriptionCancelled => {
    return function CancelSubscriptionSubscriptionCancelled(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CancelSubscriptionSubscriptionCancelled');
        return <ResolvedStandaloneCancelSubscriptionSubscriptionCancelled {...mergeProps({ options: { theme } }, props)} />;
    }
}