export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

export const CLOUDFLARE_TTL = 1 * DAY;
export const CLOUDFLARE_SCROLL_PAGE_TTL = 2 * HOUR;

export const REDIS_PAGE_TTL = CLOUDFLARE_TTL - 1 * MINUTE;
export const REDIS_SCROLL_PAGE_TTL = CLOUDFLARE_SCROLL_PAGE_TTL - 1 * MINUTE;
export const REDIS_TAG_PAGE_TTL = 1 * WEEK;
export const REDIS_CONFIG_OBJECT_TTL = 5 * MINUTE;


