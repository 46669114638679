import { ButtonTheme } from '@/components/Button/theme';
import { RadioButtonTheme } from '@/components/RadioButton/theme';
import { TextAreaTheme } from '@/components/TextArea/theme';
import { tw } from '@/utils/tw';

const RadioButton = tw.theme({
  extend: RadioButtonTheme,
  slots: {
    base: ['my-0'],
  },
});

const TextArea = tw.theme({
  extend: TextAreaTheme,
  slots: {
    base: ['mb-6'],
  },
  defaultVariants: {
    variant: 'secondary',
  },
});

const Button = tw.theme({
  extend: ButtonTheme,
  base: ['w-full', 'md:max-w-55'],
});

const CancelSubscriptionFormTheme = tw.theme({
  slots: {
    base: ['p-3', 'md:p-6'],
    headline: ['mb-3', 'md:mb-4'],
    description: ['mb-6', 'md:mb-10.5'],
    form: ['flex', 'flex-col', 'md:items-center'],
    formHeadline: ['mb-4', 'md:mb-6'],
    radioGroup: ['flex', 'flex-col', 'gap-4', 'md:flex-row', 'md:w-full', 'md:justify-between', 'mb-6'],
  },
});

export default Object.assign(CancelSubscriptionFormTheme, {
  RadioButton,
  TextArea,
  Button,
});
