import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { useAppState } from 'lib/hooks/useAppState';
import { Strossle } from './Strossle';

export interface StandaloneStrossleProps extends StandaloneComponentProps<typeof Strossle> {}

export const StandaloneStrossle: StandaloneComponent<StandaloneStrossleProps> = (props) => {
  const [{ affiliate, accessLevel }] = useAppState();
  const { enabled, id, excludedAccessLevels } = getOcelotConfig('features.strossle');

  if (!enabled || !id || excludedAccessLevels.includes(accessLevel!)) return null;

  return <Strossle data-affiliate={affiliate} {...props} />;
};
