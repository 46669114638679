import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: `h-[69px] md:h-[44px]`,
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      primary: {
        base: `h-[69px] md:h-[44px]`,
        item: `bg-yellow-600 data-[show-blip=false]:pl-4`,
        headline: `text-body-sm md:text-body-md`,
        label: `!font-medium text-logga-600 text-body-sm md:text-body-md`,
      },
    },
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
