import { ButtonTheme } from '@/components/Button/theme';
import { LinkTheme } from '@/components/Link/theme';
import { SubscriptionDetailsDigitalHistoryItemTheme } from '@/components/standalone/SubscriptionDetails/Digital/History/Item/theme';
import { tw } from '@/utils/tw';

const SubscriptionDetailsDigitalHistoryTheme = tw.theme({
  slots: {
    actionsGroup: [],
    base: [],
    headline: [],
    list: [],
  },
});

export default Object.assign(SubscriptionDetailsDigitalHistoryTheme, {
  Button: ButtonTheme,
  Item: SubscriptionDetailsDigitalHistoryItemTheme,
  Link: LinkTheme,
});
