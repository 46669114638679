import { SliderTheme } from '@/components/Slider/theme';
import { merge } from '@/utils/merge';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import { register } from 'swiper/element/bundle';

register();

const $ = GenericSlotFactory({ theme: SliderTheme });

const Base = $({ as: 'swiper-container', slot: 'base' });
const Slide = $({ as: 'swiper-slide', slot: 'slide' });

export const Slider = merge(Base, { Slide });
