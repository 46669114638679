import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Fragment, ReactNode } from 'react';
import { isUndefined } from 'typesafe-utils';
import { LoopAround } from './LoopAround';

export interface StandaloneLoopAroundProps extends StandaloneComponentProps<typeof LoopAround> {
  fragment?: ReactNode;
  length?: number;
  link?: OptionalLinkProps;
  velocity?: number;
}

export const StandaloneLoopAround: StandaloneComponent<StandaloneLoopAroundProps> = ({
  fragment,
  length = 20,
  link,
  velocity = -2,
  ...props
}) => {
  const vector = velocity / Math.abs(velocity);
  const edge = ((vector * 100) / length) * (velocity > 0 ? -1 : 1);

  const from = velocity > 0 ? edge : 0;
  const to = velocity > 0 ? 0 : edge;
  const duration = (3 / Math.abs(velocity)).toPrecision(2);

  const style = {
    '--loop-from': `${from}%`,
    '--loop-to': `${to}%`,
    '--loop-duration': `${duration}s`,
  } as React.CSSProperties;

  if (isUndefined(fragment)) {
    return null;
  }

  return (
    <LoopAround data-nosnippet {...props}>
      <OptionalLink {...link}>
        <LoopAround.Reel {...{ style }}>
          {Array.from({ length }, (_, index) => (
            <Fragment key={index}>{fragment}</Fragment>
          ))}
        </LoopAround.Reel>
      </OptionalLink>
    </LoopAround>
  );
};
