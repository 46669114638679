import { SliderTheme } from '@/components/Slider/theme';
import { tw } from '@/utils/tw';

const SlideShow = tw.theme({
  extend: SliderTheme,
  slots: {
    base: ['w-auto', 'max-w-full', 'not-prose'],
    slide: ['!aspect-auto', 'p-2', 'w-full', 'bg-inherit'],
  },
});

export default SlideShow;
