import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode, useRef } from 'react';
import { ArticlePaywall } from './ArticlePaywall';
import useOrderCreate from '@/hooks/useOrderCreate';

export interface ArticlePaywallProps extends StandaloneComponentProps<typeof ArticlePaywall> {
  description?: ReactNode;
  headline?: ReactNode;
  logo?: ReactNode;
  dialog?: ComponentProps<typeof ArticlePaywall.Dialog>;
  form?: ComponentProps<typeof ArticlePaywall.Form>;
  klarnaPayment?: ComponentProps<typeof ArticlePaywall.KlarnaPayment>;
  handleSuccessPayment?: (
    ref: React.RefObject<HTMLDialogElement>,
  ) => ComponentProps<typeof ArticlePaywall.KlarnaPayment>['handleSuccess'];
  handleFailurePayment?: (
    ref: React.RefObject<HTMLDialogElement>,
  ) => ComponentProps<typeof ArticlePaywall.KlarnaPayment>['handleFailure'];
}

const paymentContainerId = 'payment-container';

export const StandaloneArticlePaywall: StandaloneComponent<ArticlePaywallProps> = ({
  description,
  headline,
  logo,
  dialog,
  form,
  klarnaPayment,
  handleSuccessPayment,
  handleFailurePayment,
  ...props
}) => {
  const { alltIdUserCode } = useUserAuth();
  const { createOrder } = useOrderCreate();
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  return (
    <ArticlePaywall {...props}>
      {logo && <ArticlePaywall.Logo>{logo}</ArticlePaywall.Logo>}
      {headline && <ArticlePaywall.Headline>{headline}</ArticlePaywall.Headline>}
      {description && <ArticlePaywall.Description>{description}</ArticlePaywall.Description>}
      {alltIdUserCode ? (
        <ArticlePaywall.KlarnaPayment
          button={{ content: 'Betala', options: { size: 'large' } }}
          handleSuccess={
            handleSuccessPayment?.(dialogRef) ??
            async function (response) {
              await createOrder(alltIdUserCode!, response.token);
            }
          }
          handleFailure={
            handleFailurePayment?.(dialogRef) ??
            async function () {
              dialogRef.current?.showModal();
              // TODO: Handle failure
            }
          }
          paymentContainerId={paymentContainerId}
          {...mergeProps(
            { options: props.options?.$klarnaPayment },

            klarnaPayment,
          )}
        />
      ) : (
        form && <ArticlePaywall.Form {...mergeProps({ options: props.options?.$form }, form)} />
      )}
      {dialog && <ArticlePaywall.Dialog options={{ ref: dialogRef }} {...dialog} />}
    </ArticlePaywall>
  );
};
