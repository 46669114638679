import { ButtonTheme } from '@/components/Button/theme';
import { CancelSubscriptionSubscriptionCancelledTheme } from '@/components/CancelSubscription/SubscriptionCancelled/theme';
import { LinkTheme } from '@/components/Link/theme';
import { tw } from '@/utils/tw';

const CancelSubscriptionTheme = tw.theme({
  slots: {
    base: ['p-3'],
    headline: ['mb-3'],
    description: ['mb-4'],
    actionsGroup: ['flex', 'flex-col', 'mx-auto', 'gap-3', 'md:max-w-80'],
  },
});

export default Object.assign(CancelSubscriptionTheme, {
  Button: ButtonTheme,
  Link: LinkTheme,
  Form: CancelSubscriptionSubscriptionCancelledTheme,
});
