import { Link as StandaloneLink } from '@/components/Link';
import { SubscriptionDetailsDigitalHistoryItemTheme } from '@/components/standalone/SubscriptionDetails/Digital/History/Item/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: SubscriptionDetailsDigitalHistoryItemTheme });

const Base = $({ as: 'li', slot: 'base' });
const Date = $({ as: 'p', slot: 'date' });
const Description = $({ as: 'p', slot: 'description' });
const Price = $({ as: 'p', slot: 'price' });
const InvoiceLink = GenericSlot({ as: StandaloneLink, theme: SubscriptionDetailsDigitalHistoryItemTheme.InvoiceLink });

export const DigitalSubscriptionDetailsHistoryItem = Object.assign(Base, {
  Date,
  Description,
  Price,
  InvoiceLink,
});
